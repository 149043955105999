import React, { useState, useEffect,useRef,useCallback} from "react";
import Form from "react-bootstrap/Form";
import { Button, Row, Col, Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axiosMain from "../../http/axios/axios_main";
import { login } from "../../reducer/authReducers";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import axiosNew from './../../http/axios/axios_new';
import { setLoginAddress } from "../../reducer/authReducers";
import { setAuthorizationToken } from "../../reducer/authReducers";
import { GoogleReCaptchaProvider,GoogleReCaptcha } from 'react-google-recaptcha-v3';
import helpers from "../../Helper/CryptoHelper";

const LoginPage = () => {
  const [ip, setIP] = useState("");
  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    console.log(res.data);
    setIP(res.data.ip);
    localStorage.removeItem('token')
    localStorage.setItem('client_ip',res.data.ip)    
  };

  useEffect(() => {
    getData();
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [token, setToken] = useState();
  const [authToken, setAuthToken] = useState();

  const [formError, setFormError] = useState("");
  const [inputdata, setInputdata] = useState({
    email: "",
    password: "",
  });
  const formdata = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputdata({ ...inputdata, [name]: value });
  };
  const onVerify = useCallback((token) => {
    setToken(token);
    
    
  });
  const verifyToken = async () =>{
    
    const apis = await axiosNew.post("/api/verify", helpers.encrypt(JSON.stringify({token:token})));
    return helpers.decrypt(JSON.stringify(apis.data));
    
  }

  const HandleSubmit = async (e) => {
  // e.preventDefault();
    var captchaValidation = 0;
    if(token!=''){
      captchaValidation=await verifyToken();
    }

    if(captchaValidation && ip!== "" ){
      let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

      if (!inputdata.email) {
        setFormError("Please Enter Email");
        // toast.warn("Please Enter Email")
      } else if (!regex.test(inputdata.email)) {
        setFormError("Please Enter valid email");
        // toast.warn("Please Enter valid Email")
      } else if (!inputdata.password) {
        setFormError("Please Enter Password ");
        // toast.warn("Please Enter Password")
      } else {
        setFormError("");
        try {

          // const response = await axios.post("https://api.medyseva.com/api/vle/login", inputdata);
          
          const response = await axiosNew.post("/api/vle/login", helpers.encrypt(JSON.stringify(inputdata)));
          
          response.data = helpers.decrypt(JSON.stringify(response.data));
          
          console.log(response, "finelogin");
          if (response?.data.original.status) {
            // console.log(response.data.user.id,'iiiiiyyyyp');
            // toast.success(response?.data.message);
            // dispatch(setLoginAddress(response.data.user._id));

            dispatch(
              login({
                user: response.data.original.user,
                isAuthenticated: true,                
              })         
            );
            localStorage.setItem('token',response.data.original.token)
            dispatch(
              setAuthorizationToken(response.data.original.token)
            );

            dispatch(
              setLoginAddress(JSON.stringify(response.data.original.last_login))
            );
          
            navigate("/dashboard",{ state: { logalert: "loginalert" } });
          }
          setInputdata({
            email: "",
            password: "",
          });
        } catch (error) {
          toast.error("Invalid Credentials");
          console.log(error);
        }
      }
    }
  };

  return (
    <>
      <div>
        <Container>
          <ToastContainer />
          <Row className="align-items-center">
            <Col md={6} className="side-main">
              <div style={{left:"-10px"}}>
                <img
                  src="assets\img\mimg.jpg"
                  class="img-fluid"
                  className="side-img"
                />
                <h1 className="side-title">Medyseva</h1>
                <center>
                <p className="side-t">Quality Healthcare through</p>
                <p className="side-ti">Telemedicine</p>
                {/* <p className="side-tele"></p> */}
                </center>
                <p className="side-foot">Medyseva ©2023 All rights reserved.</p>
              </div>
            </Col>
            <Col md={6}>
              <div className="login-main">
                <img                  
                  src="assets\img\logo\medyseva\logo.png"
                  class="img-fluid"
                  className="login-logo"
                />

                {/* <Form className="form">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="form-label">Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter UserName"
                      onChange={formdata}
                      value={inputdata.email}
                      name="email"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="form-label">Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      onChange={formdata}
                      value={inputdata.password}
                      name="password"
                    />
                  </Form.Group>

                  <Button
                    className="button"
                    variant="primary"
                    type="submit"
                    onClick={() => {
                      HandleSubmit();
                    }}
                  >
                    Sign In
                  </Button>
                </Form> */}
                <div className="form">
                  <div className="sign-box">
                    <h1 className="login-title">
                      Sign in to your Consortium account
                    </h1>
                    <div className="box-1">
                      <label className="label" htmlFor="fname" style={{float:'left'}}>
                        Enter Your Email
                      </label>
                      <input
                        className="form-control"
                        onChange={formdata}
                        value={inputdata.email}
                        type="text"
                        id
                        name="email"
                        placeholder="Your Email"
                      />
                    </div>
                    <div className="box-1">
                      <label className="label" htmlFor="lname" style={{float:'left'}}>
                        Password
                      </label>
                      <input
                        className="form-control"
                        onChange={formdata}
                        value={inputdata.password}
                        type="password"
                        id
                        name="password"
                        placeholder="Password"
                      />
                    </div>

                    <p style={{color:'red'}} className="label">{formError}</p>
                    <GoogleReCaptchaProvider reCaptchaKey="6LcV9dslAAAAAHFRmWX_NBQJt_EXb0SGiaCugQJY">
                      <GoogleReCaptcha onVerify={onVerify} />
                    </GoogleReCaptchaProvider>
                    <Button
                      className="button"
                      variant="primary"
                      type="submit"
                      onClick={() => {
                        HandleSubmit();
                      }}
                    >
                      Sign In
                    </Button>   
                    <div className="last-div">
                      <p className="label">
                        Don't have an account? <span>Create Account</span>
                      </p>
                    </div>
                  </div>
                </div>
                {/* <h1>{formError}</h1> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default LoginPage;
